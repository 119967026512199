import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import Portal from "@reach/portal";

const backdropVariants = {
  open: {
    background: "rgba(49, 56, 84,.5)",

    pointerEvents: "auto",
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
  hide: {
    pointerEvents: "none",
    background: "rgba(49, 56, 84,0)",
    transition: {
      duration: 0.5,
      ease: "easeIn",
    },
  },
};
const modalVariants = {
  open: {
    opacity: 1,
    y: 0,
    pointerEvents: "auto",
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
  hide: {
    opacity: 0,
    y: 50,
    pointerEvents: "none",
    transition: {
      duration: 0.5,
      ease: "easeIn",
    },
  },
};

function Modal({ isOpen, close, children, ...rest }) {
  return (
    <AnimatePresence>
      {isOpen && (
        <Portal>
          <motion.div
            key={"backdrop"}
            initial={"hide"}
            animate={"open"}
            exit={"hide"}
            className="fixed inset-0 flex items-center justify-center w-full h-full "
            style={{ zIndex: 100 }}
            variants={backdropVariants}
            onClick={() => {
              close();
            }}
          >
            <div className="container phone:px-0">
              <motion.div
                key={"modal"}
                initial={"hide"}
                animate={"open"}
                exit={"hide"}
                className="w-full"
                variants={modalVariants}
                {...rest}
              >
                {children}
              </motion.div>{" "}
            </div>
          </motion.div>
        </Portal>
      )}
    </AnimatePresence>
  );
}

export default Modal;
